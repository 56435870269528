import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { absoluteFill, baseGrid, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import HeroAlert from './HeroAlert'

const HomeHero = ({ headline, alert }) => {
  const { heroImage } = useStaticQuery(graphql`
    query {
      heroImage: file(
        relativePath: { eq: "lower-east-side-skyline.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 70, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const section = css`
    ${baseGrid}
    grid-template-rows: 4.5rem 1fr;
    position: relative;
    ${mq().small} {
      grid-template-rows: 9rem 1fr;
    }
  `
  const backgroundImage = css`
    ${absoluteFill}
    background-color: ${colors.brickDark};
    z-index: 0;
    overflow: hidden;
  `
  const gatsbyImageStyle = {
    width: '100%',
    height: '100%',
    position: 'fixed',
  }
  const heading = css`
    grid-column: 2 / -2;
    grid-row: 2 / 3;
    z-index: 1;
    display: block;
    text-align: center;
    color: white;
    margin: 1.5em 0 1em;
    span {
      display: inline-block;
      ${mq().medium} {
        display: inline;
      }
    }
  `
  return (
    <section css={section}>
      <div css={backgroundImage}>
        <GatsbyImage
          image={heroImage.childImageSharp.gatsbyImageData}
          alt=""
          objectPosition={'50% 0%'}
          style={gatsbyImageStyle}
        />
      </div>
      <h1
        css={heading}
        dangerouslySetInnerHTML={{
          __html: `<span>${headline.replace(
            /\n/g,
            '</span> <span>'
          )}</span>`,
        }}
      />
      <HeroAlert alert={alert} />
    </section>
  )
}

export default HomeHero
