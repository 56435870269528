import { css, keyframes } from '@emotion/react'
import React, { useEffect, useRef, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { absoluteFill, button, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import Select from './Select'
import SelectOtherSwap from './SelectOtherSwap'
import TextInput from './TextInput'

const HotlineForm = ({ formText, activeLang }) => {
  const ref = useRef()

  const [formData, setFormData] = useState({})
  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const [submitDisabled, setSubmitDisabled] = useState(true)
  useEffect(() => {
    const requiredNodes =
      ref.current && ref.current.querySelectorAll('[required]')
    let required = []
    requiredNodes.forEach(node => {
      required = [...required, node.value]
    })
    const isFilled = value => value.length > 0
    setSubmitDisabled(!required.every(isFilled))
  }, [formData])

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [submitted, setSubmitted] = useState(false)
  const [processing, setProcessing] = useState(false)
  const handleSubmit = async e => {
    e.preventDefault()
    setProcessing(true)
    if (!executeRecaptcha) {
      return
    }
    try {
      const token = await executeRecaptcha('signup')
      const recaptchaResponse = await fetch(
        `/.netlify/functions/verifyRecaptcha`,
        {
          method: 'POST',
          body: token,
        }
      )
      const { score } = await recaptchaResponse.json()
      if (score > 0.5) {
        const timestamp = new Date().toUTCString()
        const sheetResponse = await fetch(
          `/.netlify/functions/appendSpreadsheet`,
          {
            method: 'POST',
            body: JSON.stringify({
              ...formData,
              Timestamp: timestamp,
              'Notification Sent': 'No',
            }),
          }
        )
        if (sheetResponse) {
          setProcessing(false)
          if (sheetResponse.ok) {
            setSubmitted(true)
          } else {
            throw new Error(
              'Unable to process request. If the problem persists, please try again later.'
            )
          }
        }
      }
    } catch (error) {
      alert(error)
    }
  }
  const container = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 0 0 2em 2em;
    background-color: ${colors.brick};
    color: white;
    padding: 0 max(2.5vw, 1.5rem);
    h2 {
      margin: 1em 0 0.25em;
    }
    hr {
      width: 100%;
      border: none;
      border-bottom: 1px solid #ffffff88;
      margin-bottom: 3rem;
    }
  `
  const setup = css`
    line-height: 1.333;
    display: inline-block;
    margin-bottom: 0.25em;
  `
  const hours = css`
    line-height: 1.333;
    display: inline-block;
    font-size: var(--fs-16);
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin: 0 0 1em;
    color: #ffffffcc;
    span {
      display: inline-block;
      margin: 0 0.5em;
    }
  `
  const hotlineNumber = css`
    font-size: var(--fs-36);
    color: white;
    display: inline-block;
    text-decoration: none;
    transition: color 150ms ease;
    letter-spacing: 0.05em;
    margin: 0.25em 0 1em;
    &:hover {
      color: #ffffffaa;
    }
  `
  const formStyle = css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: max(1.25vw, 1.5rem);
    margin: 1rem 0 var(--pd-md);
    ${mq().small} {
      grid-template-columns: 1fr;
    }
    ${submitted &&
    css`
      display: none;
    `}
  `
  const submitButton = css`
    display: flex;
    position: relative;
    border-radius: 0.5em;
    padding: 0.333em 0;
    color: ${colors.brickText};
    background-color: ${colors.greyLight};
    transition: background-color 300ms ease, transform 75ms ease;
    &:hover {
      background-color: white;
      input {
        color: currentColor;
      }
    }
    &:active {
      transform: scale3d(0.95, 0.95, 1);
    }
    input {
      ${button}
      border: none;
      color: currentColor;
      flex: 1;
    }
    ${submitDisabled &&
    css`
      background-color: ${colors.brickLight};
      color: ${colors.brickDarker};
      &:hover {
        background-color: ${colors.brickLight};
        input:hover {
          cursor: default;
          transform: none;
        }
      }
    `}
    ${processing &&
    css`
      background-color: transparent;
      color: transparent;
      border: 2px solid white;
      &:hover {
        background-color: transparent;
      }
    `}
  `
  const dotAnimation = keyframes`
    0% {
      opacity: 0;
      transform: scale3d(0.5, 0.5, 1);
    }
    33% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
    67% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
    100% {
      opacity: 0;
      transform: scale3d(0.5, 0.5, 1);
    }
  `
  const processingDots = css`
    display: flex;
    ${absoluteFill};
    align-items: center;
    justify-content: center;
    > span {
      width: 1rem;
      height: 1rem;
      margin: 0.5rem;
      background-color: white;
      clip-path: circle();
      animation-name: ${dotAnimation};
      animation-duration: 1000ms;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      &:nth-of-type(3n - 1) {
        animation-delay: 100ms;
      }
      &:nth-of-type(3n) {
        animation-delay: 200ms;
      }
    }
  `
  const submittedStyle = css`
    padding: var(--pd-sm) 0 var(--pd-md);
    margin-top: var(--pd-sm);
    border-top: 1px solid #ffffff88;
  `
  return (
    <div css={container} id="help">
      <h2>{formText.headline}</h2>
      {/* <LanguageSelector activeLang={activeLang} css={selector} /> */}
      <div
        css={setup}
        dangerouslySetInnerHTML={{ __html: formText.hotlineSetup }}
      />
      <a
        css={hotlineNumber}
        href={'tel:' + formText.hotlineNumber.replace(/[\D]+/g, '')}
      >
        {formText.hotlineNumber}
      </a>
      <div
        css={hours}
        dangerouslySetInnerHTML={{ __html: formText.hotlineHours }}
      />
      <hr />
      <p>{formText.formSetup}</p>
      <form
        ref={ref}
        name="hotline"
        method="POST"
        onSubmit={handleSubmit}
        css={formStyle}
      >
        <TextInput
          name="Name"
          label={formText.fields.name}
          required
          onChange={handleChange}
        />
        <SelectOtherSwap
          name="ZIP"
          label={formText.fields.zip}
          options={formText.fields.zipOptions}
          required
          onChange={handleChange}
        />
        <TextInput
          name="Phone"
          type="tel"
          label={formText.fields.phone}
          required
          onChange={handleChange}
        />
        <TextInput
          name="Email"
          type="email"
          label={formText.fields.email}
          onChange={handleChange}
        />
        <Select
          name="Language"
          label={formText.fields.language}
          options={formText.fields.languageOptions}
          onChange={handleChange}
        />
        <div css={submitButton}>
          <input
            name="Submit"
            type="submit"
            value={formText.fields.submit}
            aria-label={formText.fields.submit}
            disabled={processing || submitted}
          />
          {processing && (
            <div css={processingDots}>
              <span />
              <span />
              <span />
            </div>
          )}
        </div>
      </form>
      {submitted && (
        <div css={submittedStyle}>
          <h3>{formText.submitted}</h3>
        </div>
      )}
    </div>
  )
}

export default HotlineForm
