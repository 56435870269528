import { useEffect } from 'react'

export const useFocusTrap = (element, condition) => {
  const focusableElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

  useEffect(() => {
    if (condition && element) {
      element.querySelectorAll(focusableElements)[0].focus()
    }
    return () => {}
  }, [element, condition])

  const tabFunction = e => {
    const focusableContent = [
      ...element.querySelectorAll(focusableElements),
    ]
    const firstFocusableElement = focusableContent[0]
    const lastFocusableElement =
      focusableContent[focusableContent.length - 1]

    let isTabPressed = e.key === 'Tab' || e.keyCode === 9
    if (condition && element && isTabPressed) {
      if (e.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === firstFocusableElement) {
          e.preventDefault()
          lastFocusableElement.focus() // add focus for the last focusable element
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          e.preventDefault()
          firstFocusableElement.focus() // add focus for the first focusable element
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', tabFunction, false)
    return () => {
      document.removeEventListener('keydown', tabFunction, false)
    }
  })
}

export default useFocusTrap
