import { Global, css, keyframes } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { rgba } from 'polished'
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'

import LangContext from '../context/LangContext'
import useFocusTrap from '../hooks/useFocusTrap'
import useLangLink from '../hooks/useLangLink'
import { absoluteFill, baseGrid, closeX, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import Article from './Article'

const Lightbox = ({ slug, linkText, linkFileName }) => {
  const { allArticles } = useStaticQuery(graphql`
    query {
      allArticles: allMdx(
        filter: { frontmatter: { directory: { eq: "articles" } } }
      ) {
        nodes {
          frontmatter {
            ...frontmatter
          }
          body
        }
      }
    }
  `)

  const lang = useContext(LangContext).lang
  const [article, setArticle] = useState()
  useLayoutEffect(() => {
    for (let i = 0; i < allArticles.nodes.length; i++) {
      if (
        allArticles.nodes[i].frontmatter.slug === slug &&
        allArticles.nodes[i].frontmatter.lang === lang
      ) {
        setArticle(allArticles.nodes[i])
        break
      }
    }
  }, [allArticles, slug, lang])

  const url = `${useLangLink(lang)}/articles/${slug}`

  const domReady = useRef(false)
  useEffect(() => {
    domReady.current = true
  }, [])
  const portalTarget =
    domReady.current && document.getElementById('lightbox-container')

  const [open, setOpen] = useState(false)
  const [closing, setClosing] = useState(false)

  const handleOpen = e => {
    e.preventDefault()
    setOpen(true)
    if (url) {
      window.history.pushState(null, null, url)
    }
  }

  const closeLightbox = () => {
    setClosing(true)
    setTimeout(() => {
      setClosing(false)
      setOpen(false)
    }, 300)
  }

  const handleClose = () => {
    if (!url) {
      closeLightbox()
    } else {
      window.history.back()
    }
  }

  const handleBack = () => {
    if (url && url !== window.location.pathname) {
      closeLightbox()
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', handleBack, { passive: true })
    return () => {
      window.removeEventListener('popstate', handleBack, {
        passive: true,
      })
    }
  })
  const escFunction = e => {
    if (e.keyCode === 27 && open) {
      handleClose()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  })

  useFocusTrap(portalTarget, open)

  const animateBlurIn = keyframes`
    0% {
      background-color: transparent;
    }
    97% {
      background-color: ${rgba(colors.slate, 0.75)};
      backdrop-filter: blur(0);
    }
    100% {
      background-color: ${rgba(colors.slate, 0.75)};
      backdrop-filter: blur(0.333rem);
    }
  `
  const animateBlurOut = keyframes`
    0% {
      backdrop-filter: blur(0.333rem);
      background-color: ${rgba(colors.slate, 0.75)};
    }
    1% {
      backdrop-filter: blur(0);
      background-color: ${rgba(colors.slate, 0.75)};
    }
    100% {
      background-color: transparent;
    }
  `
  const animateContentIn = keyframes`
    from {
      transform: translate3d(0, min(33%, 12rem), 0);
      opacity: 0;
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  `
  const animateContentOut = keyframes`
    from {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    to {
      transform: translate3d(0, min(33%, 12rem), 0);
      opacity: 0;
    }
  `
  const lightboxStyle = css`
    ${baseGrid}
    grid-template-rows: 2.5vw auto 2.5vw;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: scroll;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 11;
    .close {
      position: relative;
      grid-column: 1 / -1;
      grid-row: 1 / 4;
      z-index: 0;
      animation: ${animateBlurIn} 300ms linear forwards;
      ${closing &&
      css`
        animation: ${animateBlurOut} 300ms linear forwards;
      `}
    }
    .close-x {
      cursor: pointer;
      position: fixed;
      top: 1.5vw;
      right: 1.5vw;
      width: max(4rem, 3.75vw);
      height: max(4rem, 3.75vw);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      animation: ${animateContentIn} 290ms ease-out forwards;
      ${closing &&
      css`
        animation: ${animateContentOut} 290ms ease-in 10ms forwards;
      `}
      &:after {
        ${closeX}
        content: '';
        width: max(2.5rem, 3.125vw);
        height: max(2.5rem, 3.125vw);
        transition: background-color 200ms ease-out;
      }
      &:hover:after {
        background-color: ${colors.brickLight};
      }
      ${mq().medium} {
        z-index: 9;
        top: 1rem;
        right: 1rem;
        &:before {
          ${absoluteFill};
          background-color: ${colors.slateDark};
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        &:hover:after {
          transform: none;
        }
      }
    }
  `
  const contentStyle = css`
    position: relative;
    grid-column: 2 / -2;
    grid-row: 2 / 3;
    font-size: var(--fs-18);
    width: 100%;
    max-width: calc(80ch + 10vw);
    justify-self: center;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
    align-self: center;
    display: grid;
    grid-template-columns: 2.5vw repeat(12, 1fr) 2.5vw;
    grid-column-gap: 2.5vw;
    background-color: white;
    opacity: 0;
    animation: ${animateContentIn} 290ms ease-out forwards;
    ${closing &&
    css`
      opacity: 1;
      animation: ${animateContentOut} 290ms ease-in 10ms forwards;
    `}
    ${mq().small} {
      grid-column: 1 / -1;
      margin: 0 0.5rem;
    }
  `
  const linkStyle = css``
  const scrollToggle = css`
    html {
      overflow: hidden;
    }
  `

  return (
    <>
      <a
        css={linkStyle}
        onClick={handleOpen}
        onKeyPress={handleOpen}
        aria-label="Open Lightbox"
        tabIndex={0}
        href={url}
      >
        <span>{linkText}</span>
      </a>
      {open &&
        domReady &&
        createPortal(
          <>
            <Global styles={scrollToggle} />
            <div css={lightboxStyle}>
              <div
                className="close"
                onClick={handleClose}
                aria-hidden
              />
              <div
                className="close-x"
                role="button"
                tabIndex={0}
                aria-label="Close Lightbox"
                onClick={handleClose}
                onKeyPress={handleClose}
              />
              <section css={contentStyle}>
                <Article article={article} />
              </section>
            </div>
          </>,
          portalTarget
        )}
    </>
  )
}

export default Lightbox
