import { css } from '@emotion/react'
import { rgba } from 'polished'
import React from 'react'

import { colors } from '../theme/variables'

const HeroAlert = ({ alert }) => {
  const alertStyle = css`
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    z-index: 2;
    padding: 0 max(2.5vw, 1.5rem);
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: ${rgba(colors.tea, 0.75)};
    color: white;
    h3 {
      margin: 0.5em 0;
      max-width: 67ch;
    }
  `
  const alertBg = css`
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    z-index: 1;
    background-color: ${colors.tea};
    mix-blend-mode: overlay;
    box-shadow: 0 0 3em ${rgba(colors.slateDark, 0.5)};
  `
  if (alert) {
    return (
      <>
        <div css={alertBg} />
        <div css={alertStyle}>
          <h3>{alert}</h3>
        </div>
      </>
    )
  } else return <></>
}

export default HeroAlert
