import { css } from '@emotion/react'
import { MDXProvider } from '@mdx-js/react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'
import { Helmet } from 'react-helmet'

import ContactUsBar from '../components/ContactUsBar'
import HomeHero from '../components/HomeHero'
import HotlineForm from '../components/HotlineForm'
import Layout from '../components/Layout'
import Lightbox from '../components/Lightbox'
import pattern from '../images/city-pattern.svg'
import { baseGrid, button, contentColumn, mq } from '../theme/mixins'
import { colors } from '../theme/variables'

export const data = graphql`
  query ($slug: String!, $lang: String!) {
    meta: metaJson(lang: { eq: $lang }) {
      ...meta
    }
    mdx(frontmatter: { lang: { eq: $lang }, slug: { eq: $slug } }) {
      body
      frontmatter {
        ...frontmatter
        hero
        cta {
          headline
          linkText
          linkUrl
        }
        form {
          headline
          hotlineSetup
          hotlineHours
          hotlineNumber
          formSetup
          submitted
          fields {
            name
            zip
            zipOptions
            phone
            email
            language
            languageOptions
            submit
          }
        }
        contactText
        enrollerText
        enrollerLinkText
        stateHotline
        alert
      }
    }
  }
`
const IndexPage = ({ data }) => {
  const { meta, mdx } = data
  const { frontmatter, body } = mdx
  const mainSection = css`
    ${baseGrid}
    background-color: ${colors.greyLight};
    background-image: url(${pattern});
    background-size: max(105vw, 72rem) auto;
    background-position: 50% 0%;
  `
  const mainContent = css`
    ${contentColumn}
    h2 {
      margin-top: 1.5em;
      margin-bottom: 0.25em;
      color: ${colors.brick};
    }
    h3 {
      color: ${colors.slate};
      line-height: 1.5;
    }
    h4 {
      color: ${colors.slate};
      &.coalition {
        display: inline;
      }
      &.requirements {
        margin-bottom: 0.5em;
        margin-top: 1.75em;
      }
    }
    p {
      color: ${colors.slateDark};
    }
    li {
      color: ${colors.slateDark};
    }
  `
  const ctaSection = css`
    ${contentColumn}
    max-width: calc(80ch + 5vw);
    margin-top: var(--pd-sm);
    .cta {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 2em 2em 0 0;
      background-color: ${colors.brickDarker};
      color: white;
      padding: 0 2.5vw;
      h3 {
        margin: 2em 0 1em;
        text-align: center;
      }
      a {
        ${button}
        color: white;
        margin-bottom: 2.5em;
      }
    }
    ${mq().small} {
      grid-column: 1 / -1;
      box-sizing: border-box;
      padding-left: max(1.25vw, 0.75rem);
      padding-right: max(1.25vw, 0.75rem);
    }
  `
  return (
    <Layout meta={meta} transparentNav>
      <Helmet title={frontmatter.title} />
      <MDXProvider components={{ Lightbox }}>
        <HomeHero headline={frontmatter.hero} alert={frontmatter.alert} />
        <section css={mainSection}>
          <div css={mainContent}>
            <MDXRenderer>{body}</MDXRenderer>
          </div>
          <section css={ctaSection}>
            <div className="cta">
              <h3>{frontmatter.cta.headline}</h3>
              <a
                href={frontmatter.cta.linkUrl}
                target="_blank"
                rel="noreferrer noopener"
              >
                {frontmatter.cta.linkText}
              </a>
            </div>
            <HotlineForm
              formText={frontmatter.form}
              activeLang={meta.lang}
            />
          </section>
          <ContactUsBar frontmatter={frontmatter} meta={meta} />
        </section>
      </MDXProvider>
    </Layout>
  )
}

export default IndexPage
