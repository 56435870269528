import React, { useRef, useState } from 'react'

import Select from './Select'
import TextInput from './TextInput'

const SelectOtherSwap = ({
  name,
  label,
  options = [],
  onChange = () => {},
  type,
  required,
}) => {
  const ref = useRef()
  const [other, setOther] = useState(false)
  const handleChangeSelect = (name, value) => {
    onChange(name, value)
    if (value === options[options.length - 1]) {
      setOther(true)
      onChange(name, '')
      setTimeout(() => {
        ref.current.querySelector('input').focus()
      }, 100)
    }
  }
  const handleChangeInput = (name, value) => {
    onChange(name, value)
  }

  return (
    <div ref={ref}>
      {other ? (
        <TextInput
          label={label}
          name={name}
          onChange={handleChangeInput}
          required={required}
          type={type}
        />
      ) : (
        <Select
          label={label}
          options={options}
          name={name}
          onChange={handleChangeSelect}
          required={required}
        />
      )}
    </div>
  )
}

export default SelectOtherSwap
