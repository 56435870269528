import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { downArrow } from '../theme/mixins'
import { colors } from '../theme/variables'

const Select = ({ name, label, options = [], onChange = () => {}, required = false }) => {
  const [shrink, setShrink] = useState(false)
  const [value, setValue] = useState('')

  const handleShrink = () => {
    if (value.length > 0) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }
  const handleChange = (e) => {
    setValue(e.target.value)
    onChange(e.target.name, e.target.value)
  }
  useEffect(() => {
    if (value.length > 0) {
      setShrink(true)
    }
  }, [value])

  const select = css`
    position: relative;
    padding-top: 0.5em;
    background-color: ${colors.brickDark};
    select {
      appearance: none;
      box-sizing: border-box;
      border: none;
      padding: 1em 1em 0.5em;
      line-height: 1.5;
      width: 100%;
      color: white;
      background-color: transparent;
      &:focus {
        outline: none;
      }
      &:-webkit-autofill {
        transition: all 0s 99999s;
      }
      ${!value &&
      css`
        color: transparent;
      `}
    }
    label {
      position: absolute;
      pointer-events: none;
      z-index: 2;
      top: 0;
      left: 0;
      color: white;
      max-width: calc(100% - 2.667em);
      line-height: 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translate3d(1em, 1.125em, 0);
      transition: transform 200ms ease;
      transform-origin: 0 0;
      ${shrink &&
      css`
        color: #ffffff88;
        transform: translate3d(1em, 0.125em, 0) scale3d(0.75, 0.75, 1);
      `}
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 1px;
      background-color: #ffffff88;
    }
    &:focus-within {
      background-color: ${colors.brickDarker};
      &:before {
        height: 2px;
      }
      label {
        color: #ffffffaa;
      }
    }
    &:after {
      content: '';
      ${downArrow};
      position: absolute;
      right: 1em;
      top: calc(50% + 0.1875em);
      transform: translateY(calc(-25%));
      pointer-events: none;
    }
    .required {
      display: inline-block;
      font-size: 75%;
      margin-left: 0.25em;
      transform: translateY(-0.125em);
    }
  `

  return (
    <div css={select}>
      <label htmlFor={name}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="input-base">
        <select
          name={name}
          id={name}
          required={required}
          onChange={handleChange}
          onFocus={handleShrink}
          onBlur={handleShrink}
          defaultValue=""
        >
          <option value="" disabled aria-hidden>
            {label}
          </option>
          {options.map((option, i) => (
            <option key={i} value={option.value || option}>
              {option.label || option}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
}

export default Select
