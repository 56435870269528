import { css } from '@emotion/react'
import React from 'react'

import { baseGrid, contentColumn } from '../theme/mixins'
import { colors } from '../theme/variables'
import Lightbox from './Lightbox'

const ContactUsBar = ({ frontmatter, meta }) => {
  const contactSection = css`
    ${baseGrid}
    grid-column: 1 / -1;
    color: ${colors.slateDarker};
    > div {
      ${contentColumn}
      text-align: center;
      margin-top: var(--pd-sm);
      margin-bottom: var(--pd-md);
      & > div {
        display: block;
        margin-bottom: 1em;
      }
    }
  `
  return (
    <section css={contactSection}>
      <div>
        <div>
          <span>{frontmatter.contactText}</span>{' '}
          <a href={`mailto:${meta.contactEmail}`}>
            {meta.contactEmail}
          </a>
        </div>
        <div>
          <span>{frontmatter.enrollerText}</span>{' '}
          <Lightbox
            linkText={frontmatter.enrollerLinkText}
            slug="enroller-organizations"
          />
        </div>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: frontmatter.stateHotline,
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default ContactUsBar
