import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { colors } from '../theme/variables'

const TextInput = ({
  name,
  label,
  type = 'text',
  onChange = () => {},
  required = false,
}) => {
  const [shrink, setShrink] = useState(false)
  const [value, setValue] = useState('')
  const handleFocus = () => {
    setShrink(true)
  }
  const handleBlur = () => {
    if (value.length > 0) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }
  const getFormattedPhoneNum = e => {
    let output = ''
    const inputType = e.nativeEvent.inputType
    const input = e.target.value
    input.replace(
      /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
      (match, g1, g2, g3) => {
        if (g1.length) {
          output += '(' + g1
          if (g1.length === 3) {
            output += ')'
            if (g2.length) {
              output += ' ' + g2
              if (g2.length === 3) {
                output += '-'
                if (g3.length) {
                  output += g3
                }
              }
            }
          }
        }
        if (inputType === 'deleteContentBackward') {
          if (input.length === 4 || input.length === 9) {
            output = output.slice(0, -2)
          }
          if (input.length === 6 || input.length === 10) {
            output = output.slice(0, -1)
          }
        }
      }
    )
    return output
  }

  const handleChange = e => {
    let v
    if (type === 'tel') {
      v = getFormattedPhoneNum(e)
    } else {
      v = e.target.value
    }
    setValue(v)
    onChange(e.target.name, v)
  }
  useEffect(() => {
    if (value.length > 0) {
      setShrink(true)
    }
  }, [value])

  const input = css`
    position: relative;
    padding-top: 0.5em;
    background-color: ${colors.brickDark};
    box-sizing: content-box;
    input {
      box-sizing: border-box;
      border: none;
      padding: 1em 1em 0.5em;
      line-height: 1.5;
      width: 100%;
      color: white;
      background-color: transparent;
      &:focus {
        outline: none;
      }
    }
    label {
      position: absolute;
      pointer-events: none;
      z-index: 2;
      top: 0;
      left: 0;
      color: white;
      max-width: calc(100% - 2.667em);
      line-height: 2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: translate3d(1em, 1.125em, 0);
      transition: transform 200ms ease;
      transform-origin: 0 0;
      ${shrink &&
      css`
        color: #ffffff88;
        transform: translate3d(1em, 0.125em, 0) scale3d(0.75, 0.75, 1);
      `}
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 1px;
      background-color: #ffffff88;
    }
    &:focus-within {
      background-color: ${colors.brickDarker};
      &:after {
        height: 2px;
      }
      label {
        color: #ffffffaa;
      }
    }
    .required {
      display: inline-block;
      font-size: 75%;
      margin-left: 0.25em;
      transform: translateY(-0.125em);
    }
  `

  return (
    <div css={input}>
      <label htmlFor={name}>
        {label}
        {required && <span className="required">*</span>}
      </label>
      <div className="input-base">
        <input
          value={value}
          name={name}
          id={name}
          type={type}
          required={required}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    </div>
  )
}

TextInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.any,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.string,
}

export default TextInput
